<template>
  <v-container fluid class="px-6 py-6" v-if="lesson">
    <v-row justify="end">
      <v-col lg="8" cols="12" v-if="colIgnore">
        <v-card class="border-radius-xl card-shadow">
          <div class="d-flex pb-0 px-4 py-4">
            <h6 class="text-h6 my-auto text-typo font-weight-bold me-5">
              {{ lesson.categoryName.toUpperCase() }}, {{ school.name }}
            </h6>
            <v-tabs v-model="tab" right class="overflow-hidden">
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(tabText, tabIndex) in tabTexts"
                :href="'#tab-' + (tabIndex + 1)"
                :key="'tab' + tabIndex"
              >
                {{ tabText }}
              </v-tab>
            </v-tabs>
          </div>

          <div class="px-4 py-4 mt-2">
            <v-tabs-items v-model="tab">
              <v-tab-item key="1" value="tab-1">
                <TopicTable
                  :path="path + '/topics'"
                  :headerNames="['Сэдвийн нэр', 'Үзэлт', 'Огноо']"
                  :addButtonText="'Сэдэв'"
                >
                </TopicTable>
              </v-tab-item>
              <v-tab-item key="2" value="tab-2">
                <TopicTable
                  :path="path + '/homeworks'"
                  :headerNames="['Даалгаврын нэр', 'bb', 'cc']"
                  :addButtonText="'Даалгавар'"
                >
                </TopicTable>
              </v-tab-item>
              <v-tab-item key="3" value="tab-3">
                <StudentsScoreWidget
                  :lesson="lesson"
                  :students="students"
                ></StudentsScoreWidget>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </v-col>
      <v-col lg="4" v-if="tab != null && tab == 'tab-3'">
        <h1>sdfsdfsdf</h1>
      </v-col>
      <v-col :lg="colWidth" cols="12" v-else>
        <v-card class="card-shadow border-radius-xl h-100">
          <div class="px-4 pt-4">
            <div class="d-flex flex-row">
              <h6 class="mb-0 text-h6 text-typo font-weight-bold">
                Ангиуд {{ tab }}
              </h6>
              <v-spacer></v-spacer>
              <v-btn
                small
                class="
                  font-weight-bold
                  text-capitalize
                  ms-auto
                  btn-danger
                  bg-gradient-danger
                  py-3
                  px-6
                  ms-3
                  mr-2
                "
                @click="_viewAsPreview"
                >Xичээл үзэx</v-btn
              >
              <v-btn outlined small @click="_extendTable">Дүн</v-btn>
            </div>
            <StudentsScoreWidget
              :lesson="lesson"
              :students="students"
            ></StudentsScoreWidget>
          </div>
        </v-card>
      </v-col>

      <v-col lg="4">
        <v-card
          class="pa-0 border-radius-lg mt-7 mb-9"
          :style="`background-image: url(${require('../../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
        >
          <span
            class="mask opacity-8 border-radius-lg"
            :class="`bg-gradient-` + sidebarColor"
          ></span>
          <div class="px-4 py-4 position-relative">
            <div class="docs-info">
              <v-card-text class="text-white text-h6 ps-0 pb-2"
                >QR code</v-card-text
              >
              <v-card-subtitle class="text-white text-xs font-weight-bold">
                <v-row>
                  <v-col lg="6">
                    <VueQRCodeComponent
                      :size="100"
                      text="Text to encode"
                    ></VueQRCodeComponent>
                  </v-col>
                  <v-col lg="6"> dfsdfsdf </v-col>
                </v-row>
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TopicTable from "./Widgets/TopicTable.vue";
import StudentsScoreWidget from "./Widgets/StudentsScoreWidget.vue";
import VueQRCodeComponent from "vue-qrcode-component";
import myfunction from "@/components/global/myfunction.js";
const fb = require("@/firebaseConfig.js");
export default {
  name: "smart-home",
  components: {
    TopicTable,
    VueQRCodeComponent,
    StudentsScoreWidget,
  },
  mixins: [myfunction],
  data() {
    return {
      school: null,
      showScores: false,
      sidebarColor: "success",
      students: null,
      lesson: null,
      tab: null,
      tabClassGroups: null,
      tabTexts: ["Сэдвүүд", "Даалгавар", "Scores"],
      chartConsumption: "chart-consumption",
      chartConsWeek: "chart-cons-week",
      switche: false,
      switch1: true,
      switch2: true,
      switch3: false,
      switch4: false,
      switch5: true,
      sliderValue: "",
      colIgnore: true,
      colWidth: 4,
    };
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  computed: {
    yyschool() {
      return fb.yyschool;
    },
  },
  methods: {
    _viewAsPreview() {
      this.$router.push({
        name: "PageLessonPreview",
        params: { path: this.path + "/topics" },
      });
    },
    _extendTable() {
      if (this.colIgnore == true) {
        this.colIgnore = false;
        this.colWidth = 12;
      } else {
        this.colIgnore = true;
        this.colWidth = 4;
      }
    },
    async _school() {
      var x = await fb.db.doc(this.yyschool).get();
      let school = x.data();
      school.id = x.id;
      school.ref = x.ref;
      this.school = school;
    },
  },

  created() {
    this._school();
    fb.db.doc(this.path).onSnapshot((doc) => {
      let lesson = doc.data();
      lesson.id = doc.id;
      lesson.ref = doc.ref;
      this.lesson = lesson;
      this.students = [];
      lesson.classGroups.forEach((cgroup) => {
        console.log(cgroup.classGroupRef.path);
        fb.db
          .collection(this.yyschool+"students")
          .where("classGroup", "==", cgroup.classGroupRef)
          .get()
          .then((docs) => {
            this.students[cgroup.classGroupRef.path] = [];
            docs.forEach((doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              student.classGroupRef = cgroup.classGroupRef;

              this.students[cgroup.classGroupRef.path].push(student);
            });
          });
      });
    });
  },
  mounted() {},
};
</script>

 